import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from '@axios';
import { RootState } from '@redux/reducers';
import {
    ItemResponse,
    ItemResponseApi,
    OrderResponse,
} from '@models/order/items/response';
import {
    defaultOrderRequest,
    Item,
    ItemRequest,
    OrderRequest,
    OrderRequestApi,
} from '@models/order/items/request';
import { addNotification } from '../notification';
import { ThunkCallback } from '../model/thunk-callback';
import {
    PaymentConditionResponse,
    PaymentConditionResponseApi,
} from '@models/order/paymentCondition/response';
import { toQueryParameters } from '@extensions/object';
import { Semaphore } from '@models/order/semaphore/response';
import { Pdiscqt } from '@models/order/params/response';
import { Convenio } from '@models/order/convenio/response';
import { Custo } from '@models/order/custos/response';
import {
    calculateItemCost,
    calculateOrderTotalValue,
} from '@redux/functions/order';
import moment from 'moment';
import { fetchDrafts, fetchOrdersToBonitifaction, requestChangeBranch } from '.';
import '@extensions/number';
import {
    fetchCampaignsForOrder,
    oldSeletedCampain,
    updateAvailableCampaignForOrder,
} from '../campaign';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { Moeda, MoedaSimbolo, SelectedMoeda } from '@models/order/moedas/response';
import { CustoRequest } from '@models/order/custos/request';
import { OrderResponseApi } from '@models/order/response';
import { ITEM_STATUS } from 'src/enums/item-status';
import { GROUP_TYPES } from 'src/enums/group-types';
import { EntityString } from '@models/entity';

export interface IFilterSelectItems {
    filterBy?: string;
    filterValue: string;
    status?: ITEM_STATUS
    tipoPedido?: GROUP_TYPES
}

export const fetchAvailableItems = createAsyncThunk<
    ItemResponse[],
    ItemRequest
>('select-items/fetchAvailableItems', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;

        let branchCode = state.selectItems?.order?.orderType?.platform.id

        if(state?.selectItems?.orderApi?.pedidoCopia && state?.selectType?.selectedPlatform?.id){
            branchCode = state.selectType.selectedPlatform.id
        }

        const params = toQueryParameters({
            codigoCliente: state.selectItems.order.customer.code,
            codigoFilial: branchCode,
            codigoPrazo: request.paymentConditionId,
            tipoCliente: state.selectItems.order.customer.typeId,
            tradeCliente: state.selectItems.order.customer.trade ?? '',
            zonaCliente: state.selectItems.order.customer.state,
            moeda: request.codigoMoeda ?? state.selectItems.selectedMoeda.codigo,
            ...(state.login.currentUser.itensEspecificos && {
                limitado: state.login.currentUser.itensEspecificos,
            }),
        });

        console.debug(params, request);

        const response = await axios.get<ItemResponseApi[]>(
            '/item/byusuario/?' + params,
        );

        thunkAPI.dispatch(updateItemsRequest(request));

        if (request.paymentConditionId !== '100')
            thunkAPI.dispatch(
                fetchCampaignsForOrder({
                    paymentConditionId: request.paymentConditionId,
                    date: state.selectItems?.order?.date || state?.selectItems?.orderApi?.dataProgramada,
                }),
            );

        const { data: dataReponse } = response;

        const instance = dataReponse.map((item: ItemResponseApi) =>
            Object.assign(new ItemResponseApi(), item),
        );

        console.debug({ params, instance }, 'loaded items', params);

        const items = instance
            .map((item) => {
                const itemRef = request?.tempItems?.find(
                    (i) => i.number === item.numero,
                );

                return item.toItemResponse(itemRef);
            })
            .map((i) => {
                const [itemResult] = calculateItemCost(
                    { ...i },
                    false,
                    state,
                    params,
                );
                const itemResultWithDiscount: ItemResponse = JSON.parse(
                    JSON.stringify({ ...itemResult }),
                );
                return itemResultWithDiscount;
            });

        setTimeout(() => {
            thunkAPI.dispatch(calculateTotalValue());
        }, 1000);

        return items;
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchSemaphores = createAsyncThunk<Semaphore[]>(
    'select-items/fetchSemaphores',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<Semaphore[]>(
                '/semaforo/byusuario',
            );

            const { data: dataReponse } = response;

            return dataReponse;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchPdiscqt = createAsyncThunk<void>(
    'select-items/fetchPdiscqt',
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const state = getState() as RootState;
            const { selectCustomer } = state;
            const { typeId } = selectCustomer.customer;

            const response = await axios.get<Pdiscqt[]>(
                '/pdiscqt/current/tipocliente/' + typeId,
            );

            const { data: dataReponse } = response;

            dispatch(updatePdiscqt(dataReponse));
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return rejectWithValue(message);
        }
    },
);

export const fetchConvenio = createAsyncThunk<Convenio[]>(
    'select-items/fetchConvenio',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<Convenio[]>('/convenio100/all');

            const { data: dataReponse } = response;

            return dataReponse;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchCustos = createAsyncThunk<Custo[], CustoRequest | void>(
    'select-items/fetchCustos',
    async (req, thunkAPI) => {
        try {
            const params = toQueryParameters({
                moeda: req ? req.moeda : undefined,
            });

            const response = await axios.get<Custo[]>('/custo/current?' + params);

            const { data: dataReponse } = response;

            return dataReponse;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchMoedas = createAsyncThunk<Moeda[]>(
    'select-items/fetchMoedas',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<Moeda[]>('/moeda/all');

            const { data: dataReponse } = response;

            return dataReponse;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchMoedasSimbolos = createAsyncThunk<MoedaSimbolo[]>(
    'select-items/fetchMoedasSimbolo',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<MoedaSimbolo[]>('/moeda/simbolo');

            const { data: dataReponse } = response;

            return dataReponse;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface SendOrderProps {
    requestOrder?: Partial<OrderRequest>;
    save?: boolean;
    bonificate?: boolean;
}

export const cloneApiOrder = createAsyncThunk<
    void,
    ThunkCallback<string>
>('select-items/saveOrder', async (req, thunkAPI) => {
    try {

        const response = await axios.post<OrderResponse>(`/pedido/copia/${req.data}`);

        if (response.status === 200) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.orderCreatedAndDraft'),
                    title: translate('general.success'),
                    notificationKey: req.notificationKey,
                    callback: () => req.onSuccess(response.data.pedidoId),
                }),
            );
        }
    } catch (e) {
        if (e.response) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: e.response.data[0].msgUsuario,
                    title: translate('general.errorT'),
                }),
            );
        }
        return thunkAPI.rejectWithValue('');
    }
});

export interface ApproverOrder {
    ordem: number;
    usuarioId: number;
    usuarioNome: string;
}

export const fetchApproversForOrder = async (
    rootState: RootState,
    ref?: string,
): Promise<ApproverOrder[]> => {
    try {
        const { selectItems, campaign, bonification } = rootState;
        const { availableItems, request, order,  selectedMoeda } = selectItems;
        const { selectedOrders } = bonification;
        const { selectedCampaigns, availableCampaignForOrder } = campaign;

        const requestApi = new OrderRequestApi(
            {...order, codigoMoeda: selectItems?.selectedMoeda?.codigo},
            availableItems.filter((i) => i.quantity > 0),
            request,
            undefined,
            undefined,
            selectedCampaigns,
            availableCampaignForOrder,
        );

        if (ref) {
            requestApi.itensBonificacao = requestApi.itens;
            requestApi.itens = selectedOrders
                .find((c) => `${c.key}` === `${ref}`)
                .availableItems.map<Item>((item) => {
                    return {
                        desconto: item.discount,
                        descricaoItem: item.description,
                        ipi: item.ipi,
                        multiplo: item.quantityPresentation,
                        quantidade: item.quantity,
                        numeroItem: item.number,
                        precoTotal: item.subtotal,
                        precoUnitario: item.valor,
                        promocoes: Number(item.promotion),
                        quantidadeTotal: item.totalQuantity,
                        valorIpi: Number(item.subtotalipi),
                        valorSuframa: item.suframa,
                        valorTrade: item.valorTrade,
                        ordem: item.order,
                        semaforoCor: item.semaforo,
                        semaforoNome: item.semaforoNome,
                        precoUnitarioSemDesconto: item.valorsemdesconto,
                        precoUnitarioSemCampanha: item.valorsemdesconto,
                        precoUnitarioSemCampanhaSemDesconto:
                            item.valorsemdesconto,
                        campanhaId: item.campaign?.id,
                        codigoLinhaItem: item.codigoLinha,
                        codigoTipoTransporte: item.codigoTipoTransporte,
                    };
                });
        }
        const response = await axios.post<ApproverOrder[]>(
            '/pedido/previsaoaprovadores',
            requestApi,
        );

        return response.data;
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const saveApiOrder = createAsyncThunk<
    void,
    ThunkCallback<SendOrderProps>
>('select-items/saveOrder', async (req, thunkAPI) => {
    try {
        const { selectItems, campaign, selectType, selectSeller } = thunkAPI.getState() as RootState;
        const { availableItems, request, order, orderApi } = selectItems;
        const { selectedCampaigns, availableCampaignForOrder } = campaign;
        const { data } = req;
        const { save, bonificate } = data;

        const requestApi = new OrderRequestApi(
            {...order, codigoMoeda: selectItems?.selectedMoeda?.codigo},
            availableItems.filter((i) => i.quantity > 0),
            request,
            undefined,
            undefined,
            selectedCampaigns,
            availableCampaignForOrder,
        );

        if(orderApi?.pedidoCopia){ // if copy

            // save seller - vendedor
            if(selectSeller?.selectedSeller?.codigoJDE 
                && selectItems.orderApi.usuarioId !== selectSeller.selectedSeller.id
            ){
               Object.assign(requestApi, {codigoJDE: selectSeller?.selectedSeller?.codigoJDE})
            }
            
            // save platform - filial
            if(selectType?.selectedPlatform?.id 
                && selectItems.orderApi.codigoFilial !== selectType.selectedPlatform.id
            ){
                await thunkAPI.dispatch(requestChangeBranch({
                    codigoFilial: parseInt(selectType.selectedPlatform.id),
                    pedidoId: orderApi.id
                })).unwrap()
            }
        }

        console.info(requestApi, 'API request');

        if (selectItems.draftRef) {
            const response = await axios.put(
                '/pedido/' + selectItems.draftRef,
                requestApi,
            );

            if (response.status === 200) {
                if (bonificate || save) {
                    req.onSuccess();
                } else
                    thunkAPI.dispatch(
                        addNotification({
                            type: 'success',
                            message: translate('general.orderUpdatedAndDraft'),
                            title: translate('general.success'),
                            notificationKey: req.notificationKey,
                            callback: req.onSuccess,
                        }),
                    );

                if (bonificate) {
                    thunkAPI.dispatch(
                        fetchOrdersToBonitifaction({
                            customerId: order.customer.id,
                            platformId: Number(order.orderType.platform.id),
                            moeda: selectItems?.selectedMoeda?.codigo
                        }),
                    );

                    thunkAPI.dispatch(
                        updateOrderState({
                            draftRef: selectItems.draftRef,
                        }),
                    );
                }
                if (save) {
                    thunkAPI.dispatch(
                        sendOrder({
                            notificationKey: req.notificationKey,
                            data: [Number(selectItems.draftRef)],
                        }),
                    );
                }
            }
        }

        if (!selectItems.draftRef) {
            const response = await axios.post<OrderResponse>(
                '/pedido',
                requestApi,
            );

            if (response.status === 201) {
                if (bonificate || save) {
                    req.onSuccess();
                } else
                    thunkAPI.dispatch(
                        addNotification({
                            type: 'success',
                            message: translate('general.orderCreatedAndDraft'),
                            title: translate('general.success'),
                            notificationKey: req.notificationKey,
                            callback: () => req.onSuccess(),
                        }),
                    );

                if (bonificate) {
                    thunkAPI.dispatch(
                        fetchOrdersToBonitifaction({
                            customerId: order.customer.id,
                            platformId: Number(order.orderType.platform.id),
                            moeda: selectItems?.selectedMoeda?.codigo
                        }),
                    );

                    thunkAPI.dispatch(
                        updateOrderState({
                            draftRef: `${response.data.pedidoId}`,
                        }),
                    );
                }

                if (save) {
                    thunkAPI.dispatch(
                        sendOrder({
                            notificationKey: req.notificationKey,
                            data: [response.data.pedidoId],
                        }),
                    );
                }
            }
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const sendOrder = createAsyncThunk<void, ThunkCallback<number[]>>(
    'select-items/sendOrder',
    async (req, thunkAPI) => {
        try {
            const requestSend = await axios.post<OrderResponse>(
                '/pedido/send',
                req.data,
            );

            if (requestSend.status === 200)
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.orderSended'),
                        title: translate('general.success'),
                        notificationKey: req.notificationKey,
                        callback: req.onSuccess ? req.onSuccess : undefined,
                    }),
                );
            thunkAPI.dispatch(fetchDrafts());
        } catch (e) {
            if (e.response) {
                thunkAPI.dispatch(
                    addNotification({
                        type: 'error',
                        message: e.response.data[0].msgUsuario,
                        title: translate('general.errorT'),
                    }),
                );
            }
            return thunkAPI.rejectWithValue('');
        }
    },
);

export const fetchAvailablePaymentConditions = createAsyncThunk<
    PaymentConditionResponse[]
>('select-type/fetchAvailablePaymentConditions', async (req, thunkAPI) => {
    try {
        const response = await axios.get<PaymentConditionResponseApi[]>(
            '/prazo/byusuario',
        );

        const result = response.data.map((u) =>
            Object.assign(new PaymentConditionResponseApi(), u),
        );

        const { data, status } = response;

        if (status === 200) {
            return result.map((c) => c.toPaymentConditionResponse());
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchPaymentCondition = createAsyncThunk<
    PaymentConditionResponse,
    ThunkCallback<number>
>('select-type/fetchPaymentCondition', async (req, thunkAPI) => {
    try {
        const response = await axios.get<PaymentConditionResponseApi>(
            `/prazo/byusuario/cliente/${req.data}`,
        );

        const data = Object.assign(
            new PaymentConditionResponseApi(),
            response.data,
        );

        if (response.status === 200) {
            return data.toPaymentConditionResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const updateCampaignItems = createAsyncThunk<ItemResponse[]>(
    'select-items/updateCampaignItems',
    async (req, { dispatch, getState }) => {
        const internalState = getState() as RootState;
        const state = JSON.parse(JSON.stringify(internalState));
        const list = [];
        const itemsInList = [...state.selectItems.availableItems].map((c) => ({
            ...c,
            campaign: undefined,
            campaignIndex: undefined,
            disabled: false,
            min: undefined,
            condition: undefined,
            result: undefined,
        }));
        
        
        const campaigns = state.campaign.availableCampaignForOrder.filter((c) =>
            state.campaign.selectedCampaigns.includes(c.id),
        );
        
        const itemsOfCampain = []
        if(state.campaign.oldSeletedCampain){
            const oldCampain = state.campaign.availableCampaignForOrder.find((c) =>
                state.campaign.oldSeletedCampain === c.id,
            );
            
            dispatch(oldSeletedCampain(undefined))



            for (const condition of oldCampain?.condicoes) {
                if (condition.campo !== 'QUANTIDADE_ITEM') continue;
                const itemIndex = itemsInList.findIndex(
                    (i) =>
                        i.number.toLocaleLowerCase() ===
                        condition.numeroItem.toLocaleLowerCase(),
                );

                if (
                    itemIndex === -1 ||
                    state.selectItems.request.paymentConditionId === '100'
                )
                    continue;

                const item = { ...itemsInList[itemIndex] };
                item.campaign = undefined;
                item.condition = false;
                item.campaignIndex = undefined;
                item.condition = undefined;
                item.disabled = false;
                item.result = undefined;

                item.quantity = 0;
                item.min = undefined;

                const [itemResult] = calculateItemCost(
                    { ...item },
                    false,
                    state,
                );

                itemsOfCampain.push(itemResult);
            }
        }
        
        const itemsRemovedCampainInfo: ItemResponse[] = [
            ...itemsOfCampain,
            ...itemsInList.filter((i) => !itemsOfCampain.some((c) => c.id === i.id)),
        ]
        
        
        if (campaigns.length === 0) return itemsRemovedCampainInfo.removeDuplicatesById();

        for (const [index, campaign] of campaigns.reverse().entries()) {
            for (const condition of campaign.condicoes) {
                if (condition.campo !== 'QUANTIDADE_ITEM') continue;
                const itemIndex = itemsRemovedCampainInfo.findIndex(
                    (i) =>
                        i.number.toLocaleLowerCase() ===
                        condition.numeroItem.toLocaleLowerCase(),
                );

                if (
                    itemIndex === -1 ||
                    state.selectItems.request.paymentConditionId === '100'
                )
                    continue;

                const item = { ...itemsRemovedCampainInfo[itemIndex] };
                item.campaign = campaign;
                item.campaignIndex = index;
                item.condition = condition;
                item.min = Number(condition.valor);
                item.quantity = item.quantity || Number(condition.valor);

                const [itemResult] = calculateItemCost(
                    { ...item },
                    false,
                    state,
                );

                list.push(itemResult);
            }

            if (
                campaign.tipo === 'DESCONTO' &&
                state.selectItems.request.paymentConditionId !== '100'
            )
                for (const result of campaign.resultados) {
                    const itemIndex = itemsRemovedCampainInfo.findIndex(
                        (i) =>
                            i.number.toLocaleLowerCase() ===
                            result.numeroItem.toLocaleLowerCase(),
                    );

                    if (itemIndex === -1) continue;

                    const item = { ...itemsRemovedCampainInfo[itemIndex] };
                    item.campaign = campaign;
                    item.campaignIndex = index;
                    item.disabled = true;
                    item.result = result;
                    item.quantity = result.qtd;

                    item.discount = Number(result.desconto);

                    const [itemResult] = calculateItemCost(
                        { ...item },
                        false,
                        state,
                    );

                    list.push(itemResult);
                }

            if (
                campaign.tipo.includes('BONIFICACAO') &&
                state.selectItems.request.paymentConditionId === '100'
            ) {
                for (const result of campaign.resultados) {
                    const itemIndex = itemsRemovedCampainInfo.findIndex(
                        (i) =>
                            i.number.toLocaleLowerCase() ===
                            result.numeroItem.toLocaleLowerCase(),
                    );

                    if (itemIndex === -1) continue;

                    const item = { ...itemsRemovedCampainInfo[itemIndex] };
                    item.campaign = campaign;
                    item.campaignIndex = index;
                    item.disabled = true;
                    item.result = result;
                    item.quantity = result.qtd

                    const [itemResult] = calculateItemCost(
                        { ...item },
                        false,
                        state,
                    );

                    list.push(itemResult);
                }
            }
        }

        setTimeout(() => {
            dispatch(calculateTotalValue());
        }, 1000);

        const newList: ItemResponse[] = [
            ...list,
            ...itemsRemovedCampainInfo.filter((i) => !list.some((c) => c.id === i.id)),
        ]

        return newList;
    },
);

export const verifyItems = createAsyncThunk<Partial<ItemResponse>[], void>(
    'select-items/verifyItems',
    async (_req, { dispatch, getState, rejectWithValue }) => {
        try {
            const internalState = getState() as RootState;
            const parsedState = JSON.parse(JSON.stringify(internalState));
            const newList = parsedState.selectItems.availableItems;
            const listResult: ItemResponse[] = [];

            for (let index = 0; index <= newList.length - 1; index++) {
                const element = newList[index];
                if (
                    listResult.find(
                        (i) =>
                            i.code === element.code &&
                            !!i.result &&
                            !!i.condition,
                    )
                )
                    continue;

                const indexes = newList
                    .map((elm, idx) => (elm.code === element.code ? idx : null))
                    .filter((c) => c !== null);

                if (indexes.length === 1 || element.result) {
                    listResult.push(element);
                } else {
                    const elementAtOtherIndex = newList[indexes[1]];
                    const { condition, result } = elementAtOtherIndex;
                    const { condition: conditionB, result: resultB } = element;

                    const conditionToUse = condition ?? conditionB;
                    const resultToUse = result || resultB;
                    if (`${element.quantity}` === `${resultToUse?.qtd}`) {
                        element.condition = conditionToUse;
                        element.result = resultToUse;
                        element.discount = Number(resultToUse.desconto);
                        const [itemResult] = calculateItemCost(
                            { ...element },
                            false,
                            parsedState,
                        );

                        listResult.push(itemResult);
                    } else {
                        listResult.push(element);
                    }
                }
            }

            const concatList: ItemResponse[] = [];

            for (let index = 0; index <= listResult.length - 1; index++) {
                const element = listResult[index];

                if (element.condition && element.result) {
                    if (`${element.quantity}` === `${element.result.qtd}`)
                        continue;
                    if (`${element.quantity}` !== `${element.result.qtd}`) {
                        const clone = JSON.parse(
                            JSON.stringify({ ...element }),
                        );

                        clone.condition = undefined;
                        clone.quantity = Number(clone.result.qtd);

                        element.result = undefined;
                        element.discount = 0;

                        const [itemResult] = calculateItemCost(
                            { ...element },
                            false,
                            parsedState,
                        );

                        const [cloneResult] = calculateItemCost(
                            { ...clone },
                            false,
                            parsedState,
                        );

                        listResult[index] = itemResult;
                        concatList.push(cloneResult);
                    }
                } else continue;
            }

            dispatch(calculateTotalValue());

            return [...concatList, ...listResult];
        } catch {
            rejectWithValue('');
        }
    },
);

export const updateItemApi = createAsyncThunk<void, Partial<ItemResponse>>(
    'select-items/updateItemApi',
    async (req, { dispatch, getState }) => {
        try {
            const internalState = getState() as RootState;
            const { id, ...rest } = req;
            const index = internalState.selectItems.availableItems.findIndex(
                (i) => i.id === id,
            );

            let item = internalState.selectItems.availableItems[index];

            if (item) {
                item = { id, ...item, ...rest };

                if (rest.quantity) {
                    if (item.campaign && !item.bonification) {
                        const itemsCampaign =
                            internalState.selectItems.availableItems.filter(
                                (i) => {
                                    return (
                                        `${i.campaignIndex}` ==
                                        `${item.campaignIndex}`
                                    );
                                },
                            );

                        let quantityResult = 0;

                        for (const itemCampaign of itemsCampaign) {
                            if (itemCampaign.condition) {
                                const quantity =
                                    itemCampaign.id === item.id
                                        ? rest.quantity
                                        : Number(itemCampaign.quantity);

                                const sum = Math.floor(
                                    quantity /
                                        Number(itemCampaign.condition.valor),
                                );

                                if (quantityResult === 0) quantityResult = sum;
                                else
                                    quantityResult =
                                        sum >= quantityResult
                                            ? quantityResult
                                            : sum;
                            }
                        }

                        if (item.campaign.tipo === 'BONIFICACAO_PROPORCIONAL') {
                            dispatch(
                                updateAvailableCampaignForOrder({
                                    ...item.campaign,
                                    resultados: item.campaign.resultados.map(
                                        (r) => {
                                            const result = { ...r };
                                            if (!result.originalQtd) {
                                                result.originalQtd = result.qtd;
                                            }
                                            result.qtd =
                                                result.originalQtd *
                                                quantityResult;

                                            return result;
                                        },
                                    ),
                                }),
                            );
                        }

                        itemsCampaign
                            .filter((i) => i.campaign && i.result)
                            .forEach((i) => {
                                const tempI = { ...i };
                                if (
                                    tempI.quantity !==
                                    tempI.result.qtd * quantityResult
                                ) {
                                    tempI.quantity =
                                        tempI.result.qtd * quantityResult;

                                    const [result] = calculateItemCost(
                                        { ...tempI },
                                        false,
                                        internalState,
                                    );

                                    dispatch(updateItem(result));
                                }
                            });
                    } else if (item.kit != null) {
                        const itensKit =
                            internalState.selectItems.availableItems.filter(
                                (i) => {
                                    return `${i.number}` == `${item.kit}`;
                                },
                            );

                        itensKit.forEach((itemKit) => {
                            const tempI = { ...itemKit };
                            tempI.quantity = item.compkit * item.quantity;

                            const [itemResult] = calculateItemCost(
                                { ...tempI },
                                false,
                                internalState,
                            );

                            dispatch(updateItem(itemResult));
                        });
                    }
                }

                const [itemResult] = calculateItemCost(
                    { ...item },
                    false,
                    internalState,
                );

                console.debug(itemResult, item);
                console.debug(rest);

                dispatch(updateItem(itemResult));
                dispatch(calculateTotalValue());
            }
        } catch (e) {
            //
        }
    },
);

export const calculateTotalValue = createAsyncThunk<void>(
    'select-items/calculateTotalValue',
    async (req, { dispatch, getState }) => {
        try {
            const internalState = getState() as RootState;
            console.debug(internalState, 'estado');
            dispatch(updateOrder(calculateOrderTotalValue(internalState)));
        } catch (e) {
            //
        }
    },
);

export interface IOrderItemsSelectorState {
    draftRef?: string;
    lastUpdate?: string;
    availableItems: ItemResponse[];
    semaphores: Semaphore[];
    pdiscqt: Pdiscqt[];
    availablePaymentConditions: PaymentConditionResponse[];
    paymentCondition?: PaymentConditionResponse,
    filter?: IFilterSelectItems;
    request: ItemRequest;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
    order?: Partial<OrderRequest>;
    orderApi?: OrderResponseApi,

    selectedMoeda?: SelectedMoeda,

    isFetchingMoeda: boolean;
    isFetchingMoedaSimbolo: boolean;
    isFetchingPaymentCondition: boolean;
    isSuccessPaymentCondition: boolean;
    isErrorPaymentCondition: boolean;
    errorMessagePaymentCondition: string;
    convenios: Convenio[];
    custos: Custo[];
    moedas: Moeda[];
    moedaSimbolos: MoedaSimbolo[];
    haveIpi: boolean;
    discountPanteci: number;
}

export const initialItemsState: IOrderItemsSelectorState = {
    availableItems: [],
    semaphores: [],
    availablePaymentConditions: [],
    pdiscqt: [],
    convenios: [],
    custos: [],
    moedas: [],
    moedaSimbolos: [],
    request: {},
    isFetching: true,
    isSuccess: false,
    isError: false,
    isFetchingPaymentCondition: true,
    isFetchingMoeda: true,
    isFetchingMoedaSimbolo: true,
    isSuccessPaymentCondition: false,
    isErrorPaymentCondition: false,
    errorMessage: '',
    errorMessagePaymentCondition: '',
    order: { ...defaultOrderRequest },
    filter: {
        filterBy: 'todos',
        filterValue: '',
    },
    haveIpi: false,
    discountPanteci: 0,
};

const selectTypeSlice = createSlice({
    name: 'selectTypeSlice',
    initialState: initialItemsState,
    reducers: {
        updateItemsRequest: (state, action: PayloadAction<ItemRequest>) => {
            state.request = action.payload;

            return state;
        },
        filterItems: (
            state,
            action: PayloadAction<Partial<IFilterSelectItems>>,
        ) => {
            state.filter = { ...state.filter, ...action.payload };
        },
        updateOrderState: (
            state,
            action: PayloadAction<Partial<IOrderItemsSelectorState>>,
        ) => {
            state = { 
                    ...state, 
                    ...action.payload, 
                    custos: state.custos, 
                    moedas: state.moedas, 
                    moedaSimbolos: state.moedaSimbolos,
                    semaphores: state.semaphores,
                    convenios: state.convenios,
                    pdiscqt: state.pdiscqt,
                };

            return state;
        },
        updateOrder: (state, action: PayloadAction<Partial<OrderRequest>>) => {
            state.order = {
                ...state.order,
                ...action.payload,
                deliveryDate: action.payload.deliveryDate ?? state.order.deliveryDate,
            };
            if (
                action.payload.addCustomer !== undefined &&
                !action.payload.addCustomer
            ) {
                state.order.addCustomerSelected = undefined;
            }
        },
        updateOrderPlatform: (state, action: PayloadAction<EntityString>) => {
            state.order.orderType.platform = action.payload;

            return state;
        },
        resetSelectItemsState: (state) => {
            state = { ...initialItemsState };

            return state;
        },
        updateItem: (state, action: PayloadAction<ItemResponse>) => {
            const index = state.availableItems.findIndex(
                (c) => c.id === action.payload.id,
            );
            state.availableItems[index] = action.payload;
        },
        updatePdiscqt: (state, action: PayloadAction<Pdiscqt[]>) => {
            state.pdiscqt = action.payload;
            return state;
        },
        updateMoeda: (state, action: PayloadAction<SelectedMoeda>) => {
            state.selectedMoeda = action.payload;
            return state;
        },
    },
    extraReducers: {
        [fetchAvailableItems.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ItemResponse[]>,
        ) => {
            /* state.order = { ...state.order, tempItems: [] }; */
            state.availableItems = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAvailableItems.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchAvailableItems.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },

        [fetchSemaphores.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Semaphore[]>,
        ) => {
            state.semaphores = payload.filter(
                (s) =>
                    moment(s.dataInicial).isSameOrBefore(moment()) &&
                    moment(s.dataFinal).add(1, 'days').isSameOrAfter(moment()),
            );
            return state;
        },

        [fetchCustos.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Custo[]>,
        ) => {
            state.custos = payload.filter(
                (s) =>
                    moment(s.dataInicial).isSameOrBefore(moment()) &&
                    moment(s.dataFinal).add(1, 'days').isSameOrAfter(moment()),
            );
            // state.custos = payload;
            return state;
        },

        [fetchMoedas.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Moeda[]>,
        ) => {
            state.moedas = [...payload]
            state.isFetchingMoeda = false
            return state;
        },

        [fetchMoedasSimbolos.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<MoedaSimbolo[]>,
        ) => {
            state.moedaSimbolos = [...payload]
            state.isFetchingMoedaSimbolo = false
            return state;
        },

        [fetchConvenio.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Convenio[]>,
        ) => {
            state.convenios = payload;
            return state;
        },

        [fetchAvailablePaymentConditions.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaymentConditionResponse[]>,
        ) => {
            state.availablePaymentConditions = payload.sort(
                (a: any, b: any) => a.id - b.id,
            );
            state.isFetchingPaymentCondition = false;
            state.isSuccessPaymentCondition = true;
            return state;
        },
        [fetchAvailablePaymentConditions.rejected.toString()]: (
            state,
            action,
        ) => {
            state.isFetchingPaymentCondition = false;
            state.isErrorPaymentCondition = true;
            state.errorMessagePaymentCondition = action.payload;
        },
        [fetchAvailablePaymentConditions.pending.toString()]: (state) => {
            state.isFetchingPaymentCondition = true;
            state.isErrorPaymentCondition = false;
        },
        [fetchPaymentCondition.fulfilled.toString()]: (state, { payload }: PayloadAction<PaymentConditionResponse>) => {
            state.paymentCondition = payload;
        },
        [verifyItems.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ItemResponse[]>,
        ) => {
            if (
                JSON.stringify(payload) !== JSON.stringify(state.availableItems)
            )
                state.availableItems = payload;

            return state;
        },
        [updateCampaignItems.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ItemResponse[]>,
        ) => {
            state.availableItems = payload;
            return state;
        },
    },
});


export const orderItemsSelector = (
    state: RootState,
): IOrderItemsSelectorState => state.selectItems;

export const hasANTECSelector = (state: RootState): number => {
    const isBonification =
        state.bonification.selectedOrders &&
        state.bonification.selectedOrders.length > 0;
    if (isBonification) return -99999;

    return state.selectItems.availableItems.some(
        (i) => i.pANTECI && i.pANTECI !== 0,
    )
        ? state.selectItems.availableItems.filter(
              (i) => i.pANTECI && i.pANTECI !== 0,
          )[0].pANTECI
        : -99999;
};

export const canSaveOrderSelector = (state: RootState): boolean => {
    const { availablePaymentConditions, availableItems, request, order } =
        state.selectItems;
    const { selectedOrders } = state.bonification;
    const { minOrderValue } = state.minOrder;

    if (minOrderValue) {
        for (const minValue of minOrderValue) {
            const itemsSum = availableItems
                .filter(
                    (i) =>
                        i.codigoTipoTransporte ===
                        minValue.codigoTipoTransporte,
                )
                .reduce((acc, item) => acc + item.subtotal, 0);
            if (itemsSum < minValue.valorMinimo) return false;
        }
    }

    if (!availableItems.some((i) => i.quantity > 0)) return false;
    if (availablePaymentConditions.length === 0) return true;

    const paymentCondition = availablePaymentConditions.find(
        (pc) =>
            pc.id ===
            (selectedOrders.length > 0 ? '100' : request.paymentConditionId),
    );

    if (paymentCondition === undefined) return false;
    if (paymentCondition.minOrderValue > order.valorPedido) return false;

    return true;
};

export const canSaveReasonOrderSelector = (state: RootState): string => {
    const { availablePaymentConditions, availableItems, request, order, selectedMoeda } =
        state.selectItems;
    const { selectedOrders } = state.bonification;
    const { minOrderValue } = state.minOrder;

    if (minOrderValue) {
        let resultMessage = '';
        for (const minValue of minOrderValue) {
            const itemsSum = availableItems
                .filter(
                    (i) =>
                        i.codigoTipoTransporte ===
                        minValue.codigoTipoTransporte,
                )
                .reduce((acc, item) => acc + item.subtotal, 0);
            if (itemsSum < minValue.valorMinimo)
                resultMessage += `${translate('columns.transportType')}: ${
                    minValue.codigoTipoTransporte
                } - ${translate('forms.labels.minValue')}: ${formatMoney(
                    selectedMoeda?.simbolo,
                    minValue.valorMinimo,
                )} \n`;
        }

        if (resultMessage)
            return (
                `${translate('general.minValueTransport')} \n` + resultMessage
            );
    }

    if (!availableItems.some((i) => i.quantity > 0)) return '';
    if (availablePaymentConditions.length === 0) return '';

    const paymentCondition = availablePaymentConditions.find(
        (pc) =>
            pc.id ===
            (selectedOrders.length > 0 ? 100 : request.paymentConditionId),
    );

    if (paymentCondition === undefined) return '';
    if (paymentCondition.minOrderValue > order.valorPedido)
        return `${translate('general.minValueOf')} ${formatMoney(
            selectedMoeda?.simbolo,
            paymentCondition.minOrderValue,
        )} ${translate('general.notReached')}`;

    return '';
};

export const {
    updateItemsRequest,
    updateItem,
    filterItems,
    updateOrder,
    updateOrderState,
    resetSelectItemsState,
    updatePdiscqt,
    updateMoeda,
    updateOrderPlatform
} = selectTypeSlice.actions;

export default selectTypeSlice.reducer;
